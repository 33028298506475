// ✅ Vue imports
import { ref } from 'vue';

// 📦 External packages imports
import axios from 'axios';

// 🏗️ composable imports
import { hasPermission } from '@/Redesign/directives/guard';

// 👨‍💻 Internal packages imports

// 🌏 Global variables
const user = ref(null);
const loadingUser = ref(false);
const errorUser = ref(null);
const users = ref([]);
const loadingUsers = ref(false);
const errorUsers = ref(null);
const loadingImpersonate = ref(false);
const errorImpersonate = ref(null);

export default function useUser() {
  // 🔄 Functions
  const getMyUserInfo = async () => {
    loadingUser.value = true;
    try {
      const response = await axios.get('/user/me');

      user.value = response.data.user;
      return response.data.user;
    } catch (error) {
      errorUser.value = error;
      throw new Error(error.message);
    } finally {
      loadingUser.value = false;
    }
  };

  const getAllUsers = async () => {
    const hasPermissionToList = await hasPermission('organisation.user.list.view');
    if (await !hasPermissionToList) {
      return;
    }
    loadingUsers.value = true;
    try {
      const response = await axios.get('/users/list');
      users.value = response.data.users;
      return response.data.users;
    } catch (error) {
      errorUsers.value = error;
      throw new Error(error.message);
    } finally {
      loadingUsers.value = false;
    }
  };

  const impersonateUser = async (userId) => {
    const hasPermissionToImpersonate = await hasPermission(
      'navigation.settings.menu.impersonate_user',
    );
    if (await !hasPermissionToImpersonate) {
      return;
    }
    loadingImpersonate.value = true;
    try {
      await axios.get(`/impersonate/${userId}`);
      window.location.href = '/';
    } catch (error) {
      errorImpersonate.value = error;
      throw new Error(error.message);
    } finally {
      loadingImpersonate.value = false;
    }
  };

  const isImpersonating = async () => {
    const hasPermissionToImpersonate = await hasPermission(
      'navigation.settings.menu.impersonate_user',
    );
    if (await !hasPermissionToImpersonate) {
      return;
    }
    const response = await axios
      .get('/impersonating')
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return false;
      });
    return response;
  };

  const leaveImpersonation = async () => {
    try {
      await axios.get('/impersonate-leave');
      window.location.href = '/';
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return {
    // 🔄 Variables
    user,
    loadingUser,
    errorUser,

    // 🔄 Functions
    getMyUserInfo,
    getAllUsers,
    impersonateUser,
    isImpersonating,
    leaveImpersonation,
  };
}
